import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import firebase from '../../firebase/firebase'
import { DeleteForever, Edit } from '@material-ui/icons';
import BIdatapannel from './BIdatapannel';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    "head": {
        backgroundColor: '#2979ff',
        color: theme.palette.common.white,
    },
    "body": {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const BIpannel = () => {
    var fireDB = firebase.firestore();
    var [propertyObject, setPropertyObject] = useState([]);
    var [currentId, setCurrentId] = useState('');
    const [filteresData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const db = firebase.firestore();
                const collectionRef = db.collection('filteredProperties');
                const snapshot = await collectionRef.get();

                // Map over documents to include their IDs
                const docs = snapshot.docs.map((doc) => ({
                    id: doc.id, // Unique Firestore document ID
                    ...doc.data(), // Document fields
                }));

                setPropertyObject(docs);
            } catch (error) {
                console.error('Error fetching documents from Firestore:', error);
            }
        };

        fetchDocuments();
    }, []);

    // const handleDelete = async (id) => {
    //     await fireDB.collection("filteredProperties").doc(id).delete();
    //     setData(data.filter((item) => item.id !== id));
    //     setFilteredData(filteredData.filter((item) => item.id !== id));
    //   };

    return (
        <div style={{ marginTop: 50 }}>
            <Container maxWidth="lg">
                <Box>
                    <Grid container spacing={2} sx={{
                        boxSizing: 'borderBox',
                        display: 'flex',
                        flexFlow: 'wrap',
                        width: '100%',
                        backgroundColor: 'rgb(236, 242, 255)',
                        borderRadius: '12.25px',
                        padding: '30px 25px 20px',
                        marginBottom: '30px',
                        position: ' relative',
                        overflow: 'hidden',
                    }}>
                        <Grid item xs={12}><Typography variant="h5" sx={{ fontWeight: 500 }}>Buyer Input Data</Typography> </Grid>
                        <Grid item xs={12}><BIdatapannel {...({ currentId, propertyObject })} /></Grid>

                    </Grid>
                </Box>
            </Container>
            {/* <Container maxWidth="lg" style={{ marginTop: '1em' }}>

                <TableContainer component={Paper} style={{ margin: 'auto', maxWidth: 1300, }}>
                    <Table style={{ minWidth: 700, }}>
                        <TableHead sx={{ background: '#2c7744', color: '#fff' }}>
                            <TableRow>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Address</StyledTableCell>
                                <StyledTableCell>emailAddress</StyledTableCell>
                                <StyledTableCell>Phone Number</StyledTableCell>
                                <StyledTableCell>Edit/Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(propertyObject).map(id => {
                                return (
                                    <StyledTableRow >
                                        <StyledTableCell align='left'>{propertyObject[id].firstName}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].address}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].email}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].phoneNumber}</StyledTableCell>
                                        {/* <StyledTableCell align="left"><img src={propertyObject[id].houseImage} alt="image_png" width="100" height="80" /></StyledTableCell> 
                                        <StyledTableCell align="start">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                              onClick={() => { onDelete(id) }}
                                                startIcon={<DeleteForever />}
                                            >

                                            </Button>
                                            <p></p>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => { setCurrentId(id); window.scrollTo({ top: 0, behavior: 'smooth' }) }}
                                                startIcon={<Edit />
                                                }></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )

                            })}
                        </TableBody>
                    </Table>

                </TableContainer>
            </Container> */}
        </div>
    )
}

export default BIpannel