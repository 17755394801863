import React, { useEffect, useState } from 'react'
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from '../../firebase/firebase'
import DealsDataPannel from './DealsDataPannel';
import { Button } from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';

const Loading = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const StyledTableCell = withStyles((theme) =>
    createStyles({
        head: {
            backgroundColor: '#2979ff',
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);


export const PropertesPannel = () => {
    const classes2 = Loading();
    var fireDB = firebase.database().ref();
    var [propertyObject, setPropertyObject] = useState([])
    var [currentId, setCurrentId] = useState('')
    const [open, setOpen] = useState(false);
    //firebase data retrive

    useEffect(() => {
        fireDB.child('Homedeals').on('value', snapshot => {
            if (snapshot.val() != null)
                setPropertyObject({
                    ...snapshot.val()
                })
            else
                setPropertyObject({})
        })
    }, [])
    useEffect(() => {
        fireDB.child('Homedeals').child('url').on('child_added', snapshot => console.log("child event:", snapshot.val()))
    }, [])

    //similar to component didMount
    //Firebase data push


    const add = obj => {
        if (window.confirm('Are you sure')) {
            if (currentId === '')
                fireDB.child('Homedeals').push(
                    obj,
                    err => {
                        if (err)
                            console.log(err)
                        else
                            setCurrentId('')
                    }
                )
            else
                // fireDB.child(`Listings/${currentId}`).set(
                fireDB.child(`Homedeals/${currentId}`).update(
                    obj,
                    err => {
                        if (err)
                            console.log(err)
                        else
                            setCurrentId('')
                        console.log("obj:", obj);
                    }
                )
        }
        else {
            window.location.reload();
        }

    }

    const onDelete = async (key) => {
        setCurrentId('')
        if (window.confirm('Are you sure')) {
            setOpen(true)
            let data = [...propertyObject[key].url || '', ...propertyObject[key].floorUrl || '', ...propertyObject[key].siteurl || '', ...propertyObject[key].featuredUrl || '']

            await Promise.all(
                data.map(element =>
                    firebase.storage().refFromURL(element)
                        .delete().then(function () {
                            console.log('image deleted');
                        }).catch(function (error) {
                            console.log('an error occurred');
                        }))
            ).then(() =>
                fireDB.child(`Homedeals/${key}`).remove(
                    err => {
                        if (err) {
                            console.log(err)
                            setOpen(false)
                        }
                        else {
                            setOpen(false)
                            setCurrentId('')
                        }
                    }
                ))
        }
    }


    return (
        <div style={{ marginTop: 50 }}>
            <h1>Home improvement Deals</h1>
            <Backdrop className={classes2.backdrop}  >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth="md" style={{ textAlign: "center", padding: "10px 0px" }}>
                <DealsDataPannel style={{ marginTop: '1em' }} {...({ add, currentId, propertyObject, setPropertyObject })} />
            </Container>
            <Container maxWidth="lg" style={{ marginTop: '1em' }}>

                <TableContainer component={Paper} style={{ margin: 'auto', maxWidth: 1300, }}>
                    <Table style={{ minWidth: 700, }}>
                        <TableHead sx={{ background: '#2c7744', color: '#fff' }}>
                            <TableRow>
                                <StyledTableCell>Deal Name</StyledTableCell>
                                <StyledTableCell>Category</StyledTableCell>
                                <StyledTableCell>Starting Price</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Image</StyledTableCell>
                                <StyledTableCell>Edit/Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(propertyObject).map(id => {
                                return (
                                    <StyledTableRow >
                                        <StyledTableCell align='left'>{propertyObject[id].name}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].categories}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].startprice}</StyledTableCell>
                                        <StyledTableCell align='left'>{propertyObject[id].reviewDate}</StyledTableCell>
                                        <StyledTableCell align="left"><img src={propertyObject[id].houseImage} alt="image_png" width="100" height="80" /></StyledTableCell>
                                        <StyledTableCell align="start">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                onClick={() => { onDelete(id) }}
                                                startIcon={<DeleteForever />}
                                            >

                                            </Button>
                                            <p></p>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => { setCurrentId(id); window.scrollTo({ top: 0, behavior: 'smooth' }) }}
                                                startIcon={<Edit />
                                                }></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )

                            })}
                        </TableBody>
                    </Table>

                </TableContainer>
            </Container>
        </div>
    )
}
