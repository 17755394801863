import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import emailjs from 'emailjs-com';
import MuiAlert from '@material-ui/lab/Alert';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Container from '@material-ui/core/Container';
import { Card } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography, IconButton, TextField, FormControl } from '@mui/material';
import { Link } from 'react-router-dom';
import { PhoneAndroid } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider, DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ff0000b8',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 15,
    color: 'white',
    // fontFamily: 'initial',
}));

const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#0288d1',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 15,
    color: 'white',
    // fontFamily: 'initial',
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}
            style={{
                backgroundColor: 'rgb(5, 30, 52)',
                color: 'white',
                textAlign: 'center',
                fontFamily: 'auto',
                fontSize: '2em',
            }}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    block: {
        display: 'none',
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TodayDate = new Date().toISOString().split('T')[0]

export default function BookTour(props) {

    const classes = useStyles();
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [date, setDate] = useState()
    const [open1, setOpen1] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const isMatch = useMediaQuery('(max-width:600px)');
    const {
        Open,
        Close,
        Address,
        MlsId
    } = props

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClickError = () => {
        setOpenError(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });

    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };



    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }
    const handleEmailSubmit = e => {
        e.preventDefault();
        setProgress(true)
        setLoading(true)
        emailjs.sendForm(
            'service_b9v5hfs',
            'template_n577qqi',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
                setProgress(false);
                setLoading(false);
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
                setLoading(false);
                setProgress(false);
            });

        e.target.reset()
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={Open}
            onClose={Close}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={Close}>
                Book Your Tour
                <Divider fullWidth style={{ background: 'lightgrey', margin: '5px', marginLeft: '0px' }} />
                <div>
                    {
                        isMatch ?
                            <Stack
                                direction="column"
                                spacing={2}
                            >

                                <Item>MLS ID : {`${MlsId}`}</Item>
                                <Item2>Address : {`${Address}`}</Item2>

                            </Stack>
                            :
                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem style={{ background: 'aliceblue' }} />}
                                spacing={2}
                            >

                                <Item>MLS ID : {MlsId}</Item>
                                <Item2>Address : {`${Address}`}</Item2>

                            </Stack>

                    }

                </div>

            </BootstrapDialogTitle>
            {/* <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} style={{ marginLeft: '1.5em' }}>
                    <Typography>
                        Our Team will get back to you in 24 hours. Please call or message Deepti on
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            style={{ fontSize: 13 }}>
                            <PhoneAndroid fontSize="small" /> 425-588-8470
                        </Button>
                        for quick response.
                    </Typography>
                </Grid>
            </Grid> */}


            <DialogContent dividers>
                <form onSubmit={handleEmailSubmit}>
                    <input type="hidden" value={`${Address}`} name="address" />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <PersonIcon fontSize="medium" color="primary" />
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    required
                                    id="Name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <LocalPhoneIcon fontSize="medium" color="primary" />
                                <TextField
                                    required
                                    variant="outlined"
                                    id="Phone"
                                    name="phone"
                                    label="Phone"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} >

                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <EmailIcon fontSize="medium" color="primary" />
                                <TextField
                                    required
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            {
                                isMatch ?
                                    <Stack
                                        direction="row"
                                        justifyContent="start"
                                        alignItems="start"
                                        spacing={2}
                                    >
                                        <CalendarTodayIcon fontSize="medium" color="primary" />
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDatePicker
                                                label="Consultation Date"
                                                name="date"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <TextField {...params} name="date" />}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    :
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <CalendarTodayIcon fontSize="medium" color="primary" />
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Consultation Date"
                                                name="date"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <TextField {...params} name="date" />}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                            }

                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >

                                <MessageIcon fontSize="medium" color="primary" />
                                <TextField
                                    variant="outlined"
                                    id="message"
                                    name="message"
                                    label="Message"
                                    fullWidth
                                    autoComplete="given-name"
                                    onChange={handleChange}
                                    multiline
                                    rows={5}
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleClose}
                        autoHideDuration={3000}
                        key={vertical + horizontal}>
                        <Alert onClose={Close} severity="success">
                            Email has been sent to admin team. They will reach out to you shortly with the scheduling details. Thank you.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openError} onClose={Close}>
                        <Alert onClose={Close} severity="error">
                            Please try again!
                        </Alert>
                    </Snackbar>
                    <Divider fullWidth style={{ background: 'lightgrey', margin: '5px', marginLeft: '0px' }} />
                    <DialogActions>
                        <Grid container spacing={2} >
                            <Grid item xs={isMatch ? 12 : 8} style={{ textAlign: 'justify' }}>

                                Our Team will get back to you in 24 hours. Please call or message Deepti on
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    style={{ fontSize: 13 }}>
                                    <PhoneAndroid fontSize="small" /> 425-588-8470
                                </Button>
                                for quick response.


                            </Grid>
                            <Grid item xs={isMatch ? 12 : 4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                    endIcon={<SendIcon />}
                                    fullWidth
                                    onClick={handleClick({
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    })}
                                    sx={{backgroundColor: '#3f3326'}}
                                >
                                    Submit
                                </Button>
                            </Grid>

                        </Grid>

                    </DialogActions>
                </form >
            </DialogContent>

        </BootstrapDialog >
        // <div>
        //     <Dialog maxWidth='sm' open={Open} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper" >
        //         <DialogTitle id="form-dialog-title" style={{ backgroundColor: 'rgb(5, 30, 52)', color: 'white', textAlign: 'center', fontFamily: 'auto' }}>
        //             <Typography variant="h4">Book Your Tour</Typography>

        //             <Divider fullWidth style={{ background: 'lightgrey', margin: '5px', marginLeft: '0px' }} />
        //             {MlsId &&
        //                 <Grid container spacing={1}>
        //                     <Grid item xs={4}>
        //                         <Item>
        //                             MLS ID : {MlsId}
        //                         </Item>
        //                     </Grid>
        //                     <Grid item xs={8} style={{ textAlign: 'initial' }}>
        //                         <Typography>
        //                             Our Team will get back to you in 24 hours. Please call or message Deepti on
        //                             <Button variant="outlined" size="small"> <PhoneAndroid fontSize="small" /> 425-588-8470</Button>
        //                             for quick response.
        //                         </Typography>
        //                     </Grid>
        //                 </Grid>

        //             }
        //         </DialogTitle>

        //         <DialogContent dividers>


        //         </DialogContent>
        //     </Dialog>
        // </div >
    );
}