import React, {useEffect, useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuth } from '../user/AuthContext';
import Alert from '@material-ui/lab/Alert';
import {  useNavigate } from "react-router-dom";
import ContactLogin from '../contact/ContactLogin';
import Dialog from '@material-ui/core/Dialog';
import firebase from '../firebase/firebase';
import App from '../App';
import { createMuiTheme , ThemeProvider } from '@material-ui/core/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.dguptahomes.com/">
      DGUPTAHOMES.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop:'30px' 
    // delete margintop
  },
  paper2: {
   
    marginBottom:'30px' 
    // delete margintop
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#3f3326',
    color: '#fff'
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backdropFilter:' blur(4px)'
      }
    }
  }
});

export default function Login(props) {
    const classes = useStyles();
    const emailRef= React.createRef();
    const passwordRef= React.createRef();
    const {login} = useAuth()
    const [error, setError] = useState("")
    const[loading, setLoading]= useState(false)
    const history = useNavigate();
    const [open1, setOpen1] = React.useState(false);
    const [close, setClose] = React.useState(false);
    var user = firebase.auth().currentUser;
    const{
      Open,
      Close,
      Propp,
  }= props

  // useEffect(() => {  
  //   if(Propp.length > 2){
  //     if (user == null) {
  //       setTimeout(()=> setClose(true),2500)
  //   }

  //   }
  // },[Propp])

  useEffect(() => {  
      if (user == null) {
      setClose(true)
    }

    
  },[Open])
console.log("clossc",Propp)
    const handleClickOpen = () => {
      setOpen1(true);
    };
  
    const handleClose = () => {
      setOpen1(false);
    };

    async function handleSubmit(e) {
        e.preventDefault()
    
        try {
          setError("")
          setLoading(true)
          await login(emailRef.current.value, passwordRef.current.value)
          // history.push("#")
          setClose(false)
        } catch {
          setError("Failed to sign in")
        }
        setLoading(false)
      }
    console.log("close", close)
  return (
    <ThemeProvider theme={theme}>
    <Dialog open={close} onClose={Close} aria-labelledby="form-dialog-title" scroll="paper" 
    >
    
    <Container component="main" maxWidth="xs" variant="outlined">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        { error && <Alert severity="warning">{error}</Alert>}
        <form className={classes.form} onSubmit={handleSubmit} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={emailRef}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={passwordRef}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            disabled ={loading}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/user/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link variant="body2"  onClick={handleClickOpen}  style={{cursor:"pointer"}}>
                {"Don't have an account? Sign Up"}
              </Link>
              <ContactLogin Open={open1} Close={handleClose} property={'Login Request'} />
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={4} className={classes.paper2}>
        {/* <Copyright /> */}
      </Box>
    </Container>
    </Dialog>
    </ThemeProvider>
  );
}