import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import '../components/Singlepage.css';

function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '15px' }}>
      {'Copyright © '}
      <Link color="inherit" href={props.Url}>
        {props.domainName}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minWidth: '1337px'
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    width: '100%',
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

export default function Footer() {
  const classes = useStyles();
  const domain = window.location.hostname;
  const [domainName, setDomainName] = React.useState('');

  React.useEffect(() => {
    // Function to get the domain name from window.location
    const getDomainName = () => {
      const url = new URL(window.location.href);
      setDomainName(url.hostname);
    };

    // Call the function when the component mounts
    getDomainName();

    // Add event listener to handle changes in the URL
    const handleNavigationChange = () => {
      getDomainName();
    };

    window.addEventListener('popstate', handleNavigationChange);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
    };
  }, []);

  const getHref = () => {
    switch (domainName) {
      case 'www.dguptahomes.com':
        return "https://www.dguptahomes.com/";
      case 'www.homewastate.com':
        return "https://www.homewastate.com/";
      default:
        return "https://www.homewastate.com/";
    }
  };

  const renderText = () => {
    switch (domainName) {
      case 'www.dguptahomes.com':
        return 'DguptaHomes';
      case 'www.homewastate.com':
        return 'HomeWAState';
      default:
        return 'HomeWAState';
    }
  };

  return (
    <div style={{ background: '#eeeeee' }}>
      <CssBaseline />

      <footer className={classes.footer}>
        <Container maxWidth="md">
          <div style={{
            textAlign: 'center',
            color: 'rgb(84, 83, 88)',
            fontSize: '0.80rem',
            lineheight: '1.8',
            fontWeight: 400,
            fontFamily: 'Roboto',
            marginTop: '10px',
          }}>
            <h7>
              At
              <a href={getHref()} style={{ marginLeft: '3px' }}>
                {renderText()}
              </a> We are updating our website every 24 hours with content. We are making our best effort to keep data accurate and up to date. Whilst every effort is taken to avoid errors, we cannot accept responsibility for the accuracy of any statement, extract or information contained within the pages of this website. The contents of this site are intended for convenience only. It is your responsibility to independently verify the information on the site.
            </h7 >
          </div>
          {/* <Copyright domainName={renderText()} Url={getHref()} /> */}
        </Container>
      </footer>
    </div>
  );
}