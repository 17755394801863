import React, { useState } from 'react'
import { Paper, Typography, Button, Box, Container, Grid, Link } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import banner22 from '../../images/banner2.jpg'
import '../House.css'
import '../HouseMobile.css'
import '../Filter.css';
import image from '../../images/Deepti2.jpg'
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer';
import TextField from '@material-ui/core/TextField';
import '../../contact/Email.css'
import '../Singlepage.css';
import '../Detailpage.css';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PhoneIcon from '@material-ui/icons/Phone';
import ButtonBase from '@material-ui/core/ButtonBase';
import { deepOrange } from '@material-ui/core/colors';
import { styled } from '@mui/material/styles';
import Gallery from './Gallery';
import TestiData from './TestiData';
import Youtube from './Youtube'
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) =>
({

    root: {
        display: 'flex',
        background: 'linear-gradient(45deg, #72a3e0fc, #ff1e3d)',
        borderRadius: 15,
        padding: 10,
        maxWidth: 500,
        [theme.breakpoints.up("md")]: {
            height: 80,
        },
        alignItems: "center",
        cursor: "pointer"
    },
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        padding: 34,
        paddingTop: 150
    },
    cardGrid: {
        marginTop: "10px",
    },
    button1: {
        fontSize: '20px',
        bottom: '-343px',
        left: '-135px',
    },
    // button2: {
    //     display: 'flex',
    //     fontSize: '20px',
    //     bottom: '-301px',
    //     right: '-42rem'
    // },

    papersecard: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        padding: 34,
        paddingTop: 60
    },
    paper: {
        margin: '0',
    },
    text: {
        color: "#827e7e",
        paddingLeft: "5px",
        [theme.breakpoints.down("xs")]: {
            fontSize: 13,
        }
    },
    card: {
        display: 'flex',
        background: 'linear-gradient(45deg, #f0c27b, #4b1248)',
        borderRadius: 15,
        padding: 10,
        maxWidth: 500,
        maxHeight: 200,
        alignItems: "center",
        cursor: "pointer",
    },
    root3: {
        paddingTop: "5px",
        flexGrow: 1,
    },
    paper2: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    form: {
        width: '100%',
        padding: '10px'
    },
    icon: {
        marginTop: 50,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    gallert: {
        display: 'flex',

        borderRadius: 15,
        padding: 10,
        maxWidth: 500,
        [theme.breakpoints.up("md")]: {
            height: 80,
        },
        alignItems: "center",
        cursor: "pointer"
    },
    arrowDesk: {
        background: 'white',
        borderRadius: '30px',
        fontSize: '30px',
        color: '#2979ff'
    },
    nextDisk: {
        position: 'absolute',
        right: '16px',
        top: '45%',
    },
    backDisk: {
        position: 'absolute',
        left: '16px',
        top: '45%',
    },
    body: {
        marginTop: '8px',
        flex: 1,
        textAlign: 'justify',
        fontSize: '15px',
        fontWeight: '400',
    }
})
);


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}




const StagingComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [openPopup, setOpenPopup] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [activeStep, setActiveStep] = React.useState(0);

    const closeModal = () => {
        setOpenPopup(false)
    };

    const history = useNavigate();


    const handleClick = () => {
        setOpen(true);
    };

    const handleClickError = () => {
        setOpenError(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }

    const handleEmailSubmit = e => {
        e.preventDefault();

        setLoading(true)
        emailjs.sendForm(
            'gmail',
            'template_d54orqc',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
                setLoading(false)
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
                setLoading(false)
            });

        e.target.reset()
    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }

    return (
        <Box style={{ margin: 0, background: "white" }}>
            <Paper className={classes.bannerPaper} elevation={0}>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        style={{ color: 'white', fontWeight: '500' }}
                    >
                        LIST.
                        <br />STAGE.
                        <br />SELL.
                    </Typography>
                    <Grid item xs={12} align="center">
                        <Button
                            variant="contained"
                            size="large"
                            style={{ background: '#d91c25', color: 'white' }}
                            onClick={() => setOpenPopup(o => !o)}
                        >
                            GET QUOTATION
                        </Button>
                    </Grid>
                </Container>
                <Dialog open={openPopup} onClose={closeModal} aria-labelledby="form-dialog-title" scroll="paper">
                    <DialogTitle>
                        Request a Staging Quote
                        <IconButton
                            aria-label="close"
                            onClick={closeModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <form className={classes.form} onSubmit={handleEmailSubmit}>
                        <input type="hidden" name='heading' value="Staging Quotation Request" />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            className="text_contact"
                            onChange={handleChange}
                        />
                        <TextField
                            type=" number"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="number"
                            label="Phone Number"
                            name="number"
                            autoComplete="number"
                            className="text_contact"
                            onChange={handleChange}
                        />
                        <TextField
                            type="address"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="address"
                            label="Property Address"
                            name="address"
                            autoComplete="address"
                            className="text_contact"
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{ backgroundColor: '#3f3326', color: '#fff' }}
                            className={classes.submit}
                            disabled={loading}
                        >
                            Send
                        </Button>
                        <Snackbar open={open} onClose={closeModal}>
                            <Alert onClose={closeModal} severity="success">
                                Thankyou for reaching out to us!! Email has been sent to the admin team. They will reach out to you soon.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openError} onClose={closeModal}>
                            <Alert onClose={closeModal} severity="error">
                                Please try again!
                            </Alert>
                        </Snackbar>
                    </form>
                </Dialog>
            </Paper>
            <Box style={{ margin: 0, background: "white" }} >
                <Gallery />
            </Box>
            <Box style={{ margin: 0, background: "white" }} >
                <Container maxWidth="md" style={{ paddingBottom: '10px', }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography
                                component="h3"
                                variant="h5"
                                align="center"
                                color="text.primary"
                                gutterBottom
                                style={{ fontWeight: '500', paddingTop: '20px', }}>
                                Making the Difference in WA State Home Staging
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Youtube />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '1rem', fontWeight: '400', paddingTop: '20px', paddingBottom: '20px', textAlign: 'justify' }}>
                                Deepti Gupta Home Staging offers a wide variety of services aimed at exceeding our clients’ needs and expectations. With our luxury Staging, we will sell your home faster and maximize potential offers over asking price.
                                {/* Please call us:  425-533-3269 */}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-533-3269">
                                <ButtonBase disableRipple>
                                    <PhoneIcon color="primary" style={{
                                        width: "1em",
                                        height: "1.5em",
                                    }} />
                                    <Typography variant='h6' style={{ fontSize: "1rem", fontWeight: "400" }}>
                                        Please call us: 425-533-3269
                                    </Typography>
                                </ButtonBase>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>

            </Box>
            <Box style={{ margin: 0, background: "white" }} >
                <Container maxWidth="md" style={{
                    paddingTop: '20px',
                    paddingBottom: '10px',

                }}>
                    <Typography
                        component="h3"
                        variant="h5"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        style={{ fontWeight: '500' }}>
                        Client Testimonials
                    </Typography>
                    <TestiData />
                    {/* <AliceCarousel
                        mouseTracking
                        disableButtonsControls
                        autoHeight
                    >
                        {testi.map((reviews) => (
                            <div key={reviews.name}>
                                <Card
                                    style={{
                                        padding: '10px 20px', border: '1px solid #aaa', backgroundColor: '#ebebeb',
                                        boxShadow: '0px 0px 5px #c7c7c7',
                                    }} >
                                    <CardActionArea
                                    >
                                        <CardMedia component="img"
                                            alt="green iguana"
                                            height="200"
                                            image={reviews.image}
                                            src="Client Testimonals"
                                        />

                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'center', color: '#fda12e' }}>
                                                {reviews.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} /><StarIcon style={{ color: "red" }} />
                                        </Grid>
                                        <Grid item xs={12} className={classes.body}>
                                            <p>{reviews.long}</p>
                                        </Grid>


                                    </CardActionArea>
                                </Card>
                            </div>
                        ))}
                    </AliceCarousel> */}
                </Container>
            </Box>


            <Box style={{ margin: 0, background: "black" }}>
                <Container maxWidth="md" style={{ paddingTop: '20px' }}>
                    <Grid container >
                        <Grid item xs={12} align="center"  >
                            <Box component="span" sx={{ p: 2 }} >
                                <img src={image} width="200px" height="200px" alt="single image"
                                    style={{
                                        objectFit: "contain",  // Ensures the whole image fits inside the Avatar
                                        objectPosition: "center",  // Center the image within the Avatar
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '2em' }} align="center" >
                            <Typography variant="h6" align="center" style={{ color: "white", }}> Deepti Gupta Home Staging</Typography>
                            <br />
                            <Typography align="justify" style={{ fontSize: '1rem', fontWeight: '400', color: "white", }}>Deepti Gupta home staging is only for clients who are doing listings with us. Currently we are not providing staging services separately. </Typography>
                            <br />
                            <Typography align="justify" style={{ fontSize: '1rem', fontWeight: '400', color: "white", }}>By gaining a deep insight into our clients’ personal tastes, the specific area’s geographical trends, and providing superior customer service, we will always go the extra mile to ensure our clients are fully satisfied with the end result.</Typography>
                            <br />
                            <Button variant='contained' color="secondary" onClick={() => history('/Our-Listings')}>
                                Learn more about our past listings
                            </Button>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
            {/* <Box style={{ margin: 0, background: "white" }}>
                <Container maxWidth="md" style={{ paddingTop: '10px' }}>
                    <Grid item xs={12} align="center">
                        <Typography
                            component="h3"
                            variant="h5"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            style={{ fontWeight: '500', }}>
                            Carrers
                        </Typography>
                    </Grid >

                    <Grid item xs={12} align="center">
                        <Typography
                            align="center"
                            style={{ fontSize: '1rem', textAlign: 'justify' }}>
                            We are consistently on the look out for new talent as we grow, particularly skilled sales stylists and warehouse employees.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} align="center">
                        <Button
                            variant='contained'
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                margin: '20px',
                                // left: '7rem'
                            }}
                            onClick={() => history('/JoinOurTeam')}
                        >
                            Join our team
                        </Button>
                    </Grid>

                </Container>
            </Box> */}
            <div>
                <Footer />
            </div>
        </Box >



    )
}

export default StagingComponent
