import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  IconButton,
  Paper,
  LinearProgress,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Add, Close, Delete, Edit, ExpandMore, PhotoCamera, Refresh, Share, Sort, WhatsApp } from '@mui/icons-material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import firebase from '../../firebase/firebase';


const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: '1em'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "head": {
    backgroundColor: '#2979ff',
    color: 'white',
  },
  "body": {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const BIdatapannel = (props) => {
  var fireDB = firebase.firestore();
  var [propertyObject, setPropertyObject] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedField, setEditedField] = useState({ key: '', value: '' });
  const [editedData, setEditedData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading1, setLoading1] = useState(false);  // State for loading indicator
  const [newField, setNewField] = useState({ fieldName: '', fieldValue: '' });
  const [isAddingField, setIsAddingField] = useState(false); // Controls visibility of new field inputs
  const [imageFile, setImageFile] = useState(null); // For image file
  const [isUploading, setIsUploading] = useState(false); // To show loading during upload
  const [progress, setProgress] = useState(10);
  const [position, setPosition] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // asc or desc



  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const db = firebase.firestore();
        const collectionRef = db.collection('filteredProperties');
        const snapshot = await collectionRef.get();

        // Map over documents to include their IDs
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id, // Unique Firestore document ID
          ...doc.data(), // Document fields
        }));

        setPropertyObject(docs);
        console.log('data:', propertyObject)
      } catch (error) {
        console.error('Error fetching documents from Firestore:', error);
      }
    };

    fetchDocuments();
  }, []);

  // useEffect(() => {
  //   setSortedData(propertyObject);
  // }, [propertyObject]);

  console.log('data1:', selectedDoc);

  const handleSort = () => {
    if (propertyObject.length === 0) return;

    const sorted = [...propertyObject].sort((a, b) => (b.dateSubmitted > a.dateSubmitted) ? 1 : -1)

    setPropertyObject(sorted); // Update state with sorted data
    // setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle sort order
  };

  const handleRefresh = () => {
    if (propertyObject.length === 0) return;

    const sorted = [...propertyObject].sort((a, b) => (a.dateSubmitted > b.dateSubmitted) ? 1 : -1)

    setPropertyObject(sorted); // Re-fetch the data from Firebase
  };
  const handleEditClick = (item, index) => {
    setEditedData({ ...item, index }); // Set the data to be edited
    setSelectedIndex(index); // Save the selected index
    setEditDialogOpen(true); // Open the dialog
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const uploadImage = async (e) => {
    if (e.target.files) {
      async function task() {
        const image = e.target.files[0];
        let folderName = 'BuyerInputImages';
        const id = new Date().getTime();
        var storageRef = firebase.storage().ref(`${folderName}/${id + image.name}`)
        var uploadTask = storageRef.put(image);
        uploadTask.on('state_changed', function (snapshot) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        }, function (error) {
          console.log(error);
        },
          async filename => {
            const downloadURL = await firebase
              .storage()
              .ref("BuyerInputImages")
              .child(id + image.name)
              .getDownloadURL();
            setImageFile([downloadURL]);
          }
        )
      }
      task();
    }
  };

  const handleAddField = async () => {
    if (!newField.fieldName && !imageFile) {
      console.error("Field name or image must be provided!");
      return;
    }

    let newValue = newField.fieldValue;

    if (imageFile) {
      const imageUrl = imageFile;
      if (!imageUrl) return;
      newValue = imageUrl; // Use the uploaded image URL as the value
    }

    // Add the new field to the editedData
    setEditedData((prev) => ({
      ...prev,
      [newField.fieldName || "image"]: newValue,
    }));

    // Clear input fields and hide the add fields section
    setNewField({ fieldName: '', fieldValue: '' });
    setImageFile(null);
    setIsAddingField(false);
  };


  const handleDelete = async (docId) => {
    if (!docId) return;

    // Use window.confirm for deletion confirmation
    const isConfirmed = window.confirm("Are you sure you want to delete this document?");

    if (isConfirmed) {
      setLoading1(true); // Show loading indicator while deleting

      try {
        await firebase.firestore().collection('filteredProperties').doc(docId).delete();

        // After deletion, update the state to remove the document from the list
        setPropertyObject((prev) => prev.filter((doc) => doc.id !== docId));

        if (selectedDoc?.id === docId) setSelectedDoc(null); // Reset selected document state
        console.log('Document deleted successfully');
      } catch (error) {
        console.error('Error deleting document:', error);
      } finally {
        setLoading(false);  // Hide loading indicator after deletion
      }
    }
  }


  const handleDeleteNestedArray = async (index) => {
    if (!selectedDoc?.id || index === undefined) {
      console.error("Selected document ID or index is missing!");
      return;
    }
  
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (!isConfirmed) return;
  
    try {
      const docId = selectedDoc.id;
      const docRef = firebase.firestore().collection('filteredProperties').doc(docId);
  
      // Fetch the current document data
      const docSnapshot = await docRef.get();
      if (!docSnapshot.exists) {
        console.error("Document not found in Firestore!");
        return;
      }
  
      const filteredData = docSnapshot.data().filteredData;
  
      if (!Array.isArray(filteredData)) {
        console.error("filteredData is not an array!");
        return;
      }
  
      if (index < 0 || index >= filteredData.length) {
        console.error("Index out of bounds for nested array.");
        return;
      }
  
      // Create a new array excluding the item at the specified index
      const updatedFilteredData = filteredData.filter((_, i) => i !== index);
  
      // Update Firestore with the new array
      await docRef.update({
        filteredData: updatedFilteredData,
      });
  
      // Update local state
      setPropertyObject((prev) =>
        prev.map((doc) =>
          doc.id === docId ? { ...doc, filteredData: updatedFilteredData } : doc
        )
      );
  
      setSelectedDoc(null);
      console.log(`Nested array item at index ${index} deleted successfully from document ${docId}.`);
    } catch (error) {
      console.error("Error deleting nested array item:", error);
    }
  };
  



  const handleUpdateNestedArray = async () => {
    if (!selectedDoc?.id || selectedIndex === null) {
      console.error("Selected document or index is missing!");
      return;
    }
  
    if (!editedData || Object.keys(editedData).length === 0) {
      console.error("Edited data is missing or invalid!");
      return;
    }
  
    try {
      const docId = selectedDoc.id;
      const docRef = firebase.firestore().collection("filteredProperties").doc(docId);
  
      // Get the current document data
      const docSnapshot = await docRef.get();
      if (!docSnapshot.exists) {
        console.error("Document not found in Firestore!");
        return;
      }
  
      const filteredData = docSnapshot.data().filteredData || [];
  
      // Ensure the index is within bounds
      if (selectedIndex < 0 || selectedIndex >= filteredData.length) {
        console.error("Index out of bounds!");
        return;
      }
  
      // Replace the item in the nested array with the edited data
      filteredData[selectedIndex] = { ...editedData }; // Replace the old object entirely
  
      // Update the document in Firestore with the modified array
      await docRef.update({
        filteredData: filteredData
      });
  
      // Update local state immediately to reflect changes
      setPropertyObject((prev) =>
        prev.map((doc) =>
          doc.id === docId ? { ...doc, filteredData } : doc
        )
      );
  
      // Close the dialog after update
      setEditDialogOpen(false);
      setSelectedDoc(null);
      console.log("Nested array item updated successfully.");
    } catch (error) {
      console.error("Error updating nested array item:", error);
    }
  };
  

  // const handleAddData = async () => {
  //   if (!selectedDoc?.id) {
  //     console.error("Selected document ID is missing!");
  //     return;
  //   }

  //   try {
  //     const docId = selectedDoc.id;
  //     const docRef = firebase.firestore().collection('filteredProperties').doc(docId);

  //     // Upload the image if there is one
  //     let imageUrl = null;
  //     if (newData.image) {
  //       const storageRef = firebase.storage().ref();
  //       const imageRef = storageRef.child(`images/${newData.image.name}`);
  //       await imageRef.put(newData.image);
  //       imageUrl = await imageRef.getDownloadURL();
  //     }

  //     // Add new data to filteredData
  //     const newItem = {
  //       title: newData.title,
  //       description: newData.description,
  //       image: imageUrl, // Store image URL if available
  //       timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //     };

  //     // Get current document data
  //     const docSnapshot = await docRef.get();
  //     if (!docSnapshot.exists) {
  //       console.error("Document not found in Firestore!");
  //       return;
  //     }

  //     // Add new item to filteredData
  //     const filteredData = docSnapshot.data().filteredData || [];
  //     filteredData.push(newItem);

  //     // Update document with new filteredData
  //     await docRef.update({
  //       filteredData: filteredData,
  //     });

  //     // Optionally, update the local state or UI
  //     setPropertyObject((prev) =>
  //       prev.map((doc) =>
  //         doc.id === docId ? { ...doc, filteredData } : doc
  //       )
  //     );

  //     // Close the dialog
  //     setEditDialogOpen(false);
  //     console.log("Data added successfully!");
  //   } catch (error) {
  //     console.error("Error adding data:", error);
  //   }
  // };

  const deleteImage = async (imageUrl, stateSetter, documentId, fieldName = 'houseImage') => {
    try {
      if (!imageUrl) {
        console.error('Image URL is required for deletion.');
        return;
      }

      // Reference to the image in Firebase Storage
      const imageRef = firebase.storage().refFromURL(imageUrl);

      // Delete the image from Firebase Storage
      await imageRef.delete();
      console.log('Image successfully deleted from Firebase Storage.');

      // Update the state and/or Firestore after deletion
      if (stateSetter) {
        stateSetter((prevState) => prevState.filter((url) => url !== imageUrl));
      }

      if (documentId) {
        // Fetch the Firestore document to update the array field
        const docRef = firebase.firestore().collection('filteredProperties').doc(documentId);
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          const existingImages = docSnapshot.data()[fieldName] || [];
          const updatedImages = existingImages.filter((url) => url !== imageUrl);

          await docRef.update({ [fieldName]: updatedImages });
          console.log('Firestore document updated successfully with new image list.');
        } else {
          console.error('Document not found in Firestore.');
        }
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleWhatsAppShare = (item) => {
    const phoneNumber = selectedDoc.phoneNumber || "6380448102"; // Replace with a default number if missing
    // console.log("phb", selectedDoc.phoneNumber)
    const styledMessage = (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Recommended Property:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>MLS ID:</strong> {item.ListingNumber || "N/A"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Price:</strong> ${item.CurrentPrice?.toLocaleString() || "N/A"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Square Feet:</strong> {item.SquareFootage?.toLocaleString() || "N/A"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Lot Size:</strong> {item.LotSqFt || "N/A"}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Contact us for more details!
        </Typography>
      </Box>
    );


    const plainTextMessage = `🔥*Your Dream Property Awaits!*🔥

🌟 *PROPERTY DETAILS* 🌟
🏡 *MLS ID:* ${item.APN || "N/A"}
💰 *Price:* $ ${Number(item.CurrentPrice)?.toLocaleString() || "N/A"}
📏 *Square Feet:* ${item.SquareFootage.toLocaleString() || "N/A"} *Sq.Ft.*
🌳 *Lot Size:* ${item.LotSqFt.toLocaleString() || "N/A"} *Sq.Ft.*

✨ *Why Choose This Property?* ✨
✅ Spacious and modern design
✅ Prime location
✅ Great investment opportunity

📞 *Contact us now to schedule a visit!*
📧 Email: dguptahomes.web@gmail.com
📲 Phone: +1 (425) 533-3269

🔗 *Check out more properties here:* www.DGUPTAHOMES.com`;


    const message = encodeURIComponent(plainTextMessage);

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, "_blank"); // Open WhatsApp share link in a new tab
  };

  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <TableContainer component={Paper} sx={{ margin: 'auto', maxWidth: 1300, mt: '1em' }}>
          <Table style={{ minWidth: 700, }} >
            <TableHead sx={{ background: '#4a9fdf', color: '#fff' }}>
              <TableRow>
                <StyledTableCell >S.No</StyledTableCell>
                <StyledTableCell>MLS ID</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell>Square Feet</StyledTableCell>
                <StyledTableCell>Lot Size</StyledTableCell>
                <StyledTableCell sx={{ width: '30px' }}>WhatsApp Share</StyledTableCell>
                <StyledTableCell>Edit/Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.sort((a, b) => {
                // Ensure both 'listingnumber' values exist and are numbers
                const listingNumberA = a.ListingNumber || 0;
                const listingNumberB = b.ListingNumber || 0;

                // Compare the listing numbers numerically
                return listingNumberA - listingNumberB;
              }).map((item, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align='left'>{`${index + 1}`}</StyledTableCell>
                  <StyledTableCell align='left'>{item.ListingNumber || 'N/A'}</StyledTableCell>
                  <StyledTableCell align='left'>${item.CurrentPrice?.toLocaleString() || 'N/A'}</StyledTableCell>
                  <StyledTableCell align='left'>{item.SquareFootage?.toLocaleString() || 'N/A'}</StyledTableCell>
                  <StyledTableCell align='left'>{item.LotSqFt || 'N/A'}</StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: '30px' }}>
                    <BootstrapTooltip title="Share this property on WhatsApp">
                      <IconButton edge="start" aria-label="share" sx={{ color: '#25D366' }} onClick={() => handleWhatsAppShare(item)}>
                        <WhatsApp fontSize="large" />
                      </IconButton>
                    </BootstrapTooltip>
                  </StyledTableCell>
                  <StyledTableCell align="start">
                    <BootstrapTooltip title="Delete this property">
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNestedArray(index)} sx={{ color: '#e50f3d' }}>
                        <Delete />
                      </IconButton>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Edit this property details">
                      <IconButton edge="end" aria-label="edit" sx={{ ml: 2, color: '#0078d2' }} onClick={() => handleEditClick(item, index)}>
                        <Edit />
                      </IconButton>
                    </BootstrapTooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      );
    } else if (typeof data === 'object' && data !== null) {
      return (
        <Box sx={{ ml: 2 }}>
          {Object.entries(data)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([key, value]) => (
              <Box
                key={key}
                sx={{
                  display: typeof value === 'object' ? 'column' : 'flex',
                  alignItems: 'flex-start',
                  mb: 2,
                  p: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  borderRadius: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 2 }}>
                  {key.toUpperCase()}:
                </Typography>
                {typeof value === 'object' ? (
                  <Box sx={{ ml: typeof value === 'object' ? 0 : 2 }}>{renderData(value, key)}</Box>
                ) : (
                  <Typography variant="body2" sx={{ mr: 2 }}>
                    {value.toString()}
                  </Typography>
                )}
                {/* <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                <IconButton
                  size="small"
                  onClick={() => handleEdit(key, value)}
                  sx={{ mr: 1 }}
                >
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleFieldDelete(key)}
                  sx={{ color: 'red' }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Box> */}
              </Box>
            ))}
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          p: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {data ? data.toString() : 'N/A'}
        </Typography>
      </Box>
    );
  };



  if (loading) {
    return <CircularProgress sx={{ marginTop: '50px', marginLeft: '50%' }} />;
  }

  return (
    <div>
      <Box>
        <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            {/* <Typography variant="h7" gutterBottom sx={{fontWeight: 500}}>
              Sort by Date
            </Typography> */}
            <BootstrapTooltip title="Sort by Date">
              <IconButton onClick={handleSort} size="large" sx={{ mt: '1em' }}>
                <Sort fontSize="inherit" />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title="Refresh">
              <IconButton onClick={handleRefresh} size="large" sx={{ mt: '1em', color: '#0078d2' }}>
                <Refresh fontSize="inherit" />
              </IconButton>
            </BootstrapTooltip>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={1}>
              <Box sx={{ p: 2 }}>
                <List>
                  {propertyObject.map((doc, index) => (
                    <React.Fragment key={index}>
                      <Card
                        variant="outlined"
                        sx={{
                          mb: 2,
                          borderColor: selectedDoc?.id === doc.id ? 'primary.main' : 'grey.300',
                          backgroundColor:
                            selectedDoc?.id === doc.id ? 'rgba(25, 118, 210, 0.1)' : 'white',
                          transition: 'background-color 0.3s, border-color 0.3s',
                        }}
                      >
                        <ListItem secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(doc.id)}>
                            <Delete />
                          </IconButton>
                        } disablePadding>
                          <ListItemButton onClick={() => setSelectedDoc(doc)}>
                            <ListItemText
                              primary={`Document-${index + 1} Dated ${doc.dateSubmitted}`}
                              secondary={doc.city ? `City: ${doc.city}` : 'No additional details'}
                            />
                          </ListItemButton>
                        </ListItem>
                        {/* <CardContent>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(doc.id)}
                            startIcon={<Delete />}
                          >
                            Delete
                          </Button>
                        </CardContent> */}
                      </Card>
                      {index < propertyObject.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            {selectedDoc ? (
              <Card variant="outlined" sx={{ p: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Client Name: {selectedDoc.firstName} {selectedDoc.lastName}
                  </Typography>
                  {/* <Typography variant="body2">Phone Number: {selectedDoc.phoneNumber}</Typography> */}
                  {renderData(selectedDoc)}
                </CardContent>
              </Card>
            ) : (
              <Typography variant="body1">Select a document to view its details</Typography>
            )}
          </Grid>
        </Grid>

        {/* Edit Dialog */}
        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle sx={{ paddingBottom: '1em' }}>Edit Nested Array Item</DialogTitle>
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
            onClick={() => setEditDialogOpen(false)}
          >
            <Close />
          </IconButton>
          <DialogContent sx={{ paddingTop: '1em' }}>
            {Object.entries(editedData)
              .filter(([key]) =>
                ['ListingNumber', 'CurrentPrice', 'SquareFootage', 'LotSqFt'].includes(key)
              )
              .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
              .map(([key, value]) => (
                <TextField
                  key={key}
                  label={key.toUpperCase()}
                  value={value || ''}
                  onChange={(e) =>
                    setEditedData((prev) => ({ ...prev, [key]: e.target.value }))
                  }
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                />
              ))}

            {isAddingField && (
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="New Field Name"
                  name="fieldName"
                  value={newField.fieldName}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <TextField
                  label="New Field Value"
                  name="fieldValue"
                  value={newField.fieldValue}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <Typography variant="h6" sx={{ mb: '1em' }}>Featured Image</Typography>
                <StyledGrid xs={6} align="start">
                  <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file"
                    onChange={uploadImage} />
                  <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  <div >
                    <Box width="100%" mr={1}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 0 }}>
                          <LinearProgress variant="determinate" value={progress} />
                        </Box>

                      </Box>

                    </Box>
                  </div>
                  <div>
                    {(() => {
                      if (imageFile) {
                        return (imageFile.map((url, index) => (
                          <div key={index}>
                            <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                            <Button variant="contained" onClick={() => { deleteImage(url, setImageFile); setPosition(index) }}>
                              Delete
                            </Button>
                            {position === index &&
                              <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                            }
                          </div>
                        )))
                      }
                    })()}
                  </div>
                </StyledGrid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddField}
                  disabled={isUploading}
                  sx={{ mt: '1em' }}
                >
                  {isUploading ? "Uploading..." : "Save Field"}
                </Button>
              </Box>
            )}

            {/* Add Button */}
            {!isAddingField && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsAddingField(true)}
                sx={{ mb: 2 }}
              >
                Add Field
              </Button>
            )}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button> */}
            {
              isAddingField === true ?
                <Button disabled variant='contained' color="primary">
                  Update
                </Button>
                :
                <Button onClick={handleUpdateNestedArray} variant='contained' color="primary">
                  Update
                </Button>
            }

          </DialogActions>
        </Dialog>
        {/* {loading1 && <CircularProgress />} */}
      </Box>
    </div>
  );
};

export default BIdatapannel;
