import React from 'react';
import '../../components/AvilableHome.css';
import '../../components/Availability.css';
import { makeStyles } from '@material-ui/core/styles';
import './NavBar.css';
import banner22 from '../../images/Bannermob13.png';
import ButtonBase from '@material-ui/core/ButtonBase';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import MuiAlert from '@material-ui/lab/Alert';
import PhoneIcon from '@material-ui/icons/Phone';
import Zillow from '../../images/zillow.svg';
import Link from '@material-ui/core/Link';
import Footer from '../../components/Footer';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Avatar, Box, Paper, Rating, Stack, styled, useMediaQuery, useTheme, Typography, Button, Dialog, DialogTitle, IconButton, DialogContent, Divider, Container, Grid, Card, CardMedia, Checkbox, FormControlLabel } from '@mui/material';
import Image from '../../images/Deepti2.jpg';
import { PropertyContext } from '../../context';
import Loading from '../Loading';
import { Close, Email, Forum, Instagram, Mail, PhoneIphone } from '@mui/icons-material';
import kelly from '../../images/logoS.2.png'
import { FaFacebook, FaWhatsapp } from 'react-icons/fa';
import insta from '../../images/insta.png';
import logoS2 from '../../images/logoS.1.png'

const useStyles = makeStyles((theme, isMatch) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  gridCenter: {
    alignItems: "center"
  },
  GridSpace: {
    padding: "0px 30px",
  },
  center: {
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0px 0px 0px",
      textAlign: "center"
    }

  },
  cardGrid: {
    marginTop: "10px",
  },
  bannerPaper: {
    borderRadius: null,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 34,
    paddingTop: 60
  },
  text: {
    color: "#827e7e",
    paddingLeft: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    }
  },
  imagelogo: {
    width: "32px",
    height: "32px",

  },
  imagelogo1: {
    width: isMatch ? "32px" : "40px",
    height: isMatch ? "32px" : "40px",

  },
  text2: {
    fontSize: "20px",
    color: "#2979ff",

  },
  icon: {
    width: "1.8em",
    height: "2em",
    // [theme.breakpoints.down("xs")]:{
    //   width: "1em",
    // }
  },
  gridmobpad: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledPaper = styled(Box)(({ theme, isMatch }) => ({
  width: '55px',
  height: isMatch ? '100px' : '80px',
  background: '#024e6d',
  position: 'relative',
  overflow: 'hidden',
  top: isMatch ? '-1em' : '-2em',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '20%',
    background: 'white',
  },
  '&::before': {
    left: 0,
    transform: 'skewY(-30deg)',
    transformOrigin: 'bottom right',
  },
  '&::after': {
    right: 0,
    transform: 'skewY(30deg)',
    transformOrigin: 'bottom left',
  },
}))

const StyledDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '-57px',
  left: '0',
  right: '0',
  width: '100%',
  height: '110px',
  background: 'white',
  clipPath: 'polygon(0 50%, 25% 100%, 50% 50%, 75% 100%, 100% 50%, 50% 0)',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  borderRadius: 'none',
}))
const StyledButton = styled(Button)(({ theme, isMatch }) => ({
  width: isMatch ? '19em' : '21em',
  height: isMatch ? 38 : 40,
  borderRadius: 50,
  fontSize: isMatch ? 15 : '1.1em',// Set the border color to white
  backgroundColor: 'rgb(13, 118, 214)',
  color: '#fff',// Set the background color to transparent
  '&:hover': {
    backgroundColor: 'rgb(13, 118, 214)',
    color: '#fff', // Set the background color to white on hover
    transform: 'scale(1.05)',
  },
}))


const StyledButton1 = styled(Button)(({ theme, isMatch }) => ({
  width: isMatch ? '19em' : '12em',
  height: isMatch ? 38 : 40,
  borderRadius: 50,
  fontSize: isMatch ? 15 : '1.1em',// Set the border color to white
  backgroundColor: 'rgb(13, 118, 214)',
  color: '#fff',// Set the background color to transparent
  '&:hover': {
    backgroundColor: 'rgb(13, 118, 214)',
    color: '#fff', // Set the background color to white on hover
    transform: 'scale(1.05)',
  },
}))

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const ContactUS = () => {
  const classes = useStyles();
  const context = React.useContext(PropertyContext);
  const { testimonials, TestimonialData } = context;
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isBuyingChecked, setIsBuyingChecked] = React.useState(false);
  const [isSellingChecked, setIsSellingChecked] = React.useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  let ZillowReviews = testimonials;

  ZillowReviews = [
    ...ZillowReviews.filter(reviews => reviews.platform !== "Facebook")
  ]
  // const ZillowReviews = [
  //   ...testimonials.filter(reviews => reviews.platform === "Zillow")
  // ]

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleClick1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }

  const handleBuyingChange = (event) => {
    setIsBuyingChecked(event.target.checked);
  };

  const handleSellingChange = (event) => {
    setIsSellingChecked(event.target.checked);
  };


  const handleEmailSubmit = e => {
    e.preventDefault();

    setLoading(true)
    emailjs.sendForm(
      'service_b9v5hfs',
      'template_m7yxtzs',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setLoading(false)
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setLoading(false);
      });
    setIsBuyingChecked(false);
    setIsSellingChecked(false);
    e.target.reset()
  }

  React.useEffect(() => {
    if (ZillowReviews.length === 0) {
      TestimonialData()
    }
  }, [])

  if (ZillowReviews.length === 0) {
    return <Loading />
  }


  return (
    <div>
      <Box style={{ margin: 0, background: "white" }} >
        <Paper className={classes.bannerPaper} sx={{ borderRadius: 'none' }}>
          <Container className={classes.cardGrid} maxWidth="md">
            <div className="BAnner_text_Nav">
              <h3>Contact us </h3>
            </div>
          </Container>
        </Paper>
        <Container maxWidth="md" sx={{ mt: '2em', mb: '2em' }}>
          <Paper elevation={2} sx={{ p: isMatch ? '1em' : '2em' }}>
            <Grid container spacing={1}>
              <Grid item xs={isMatch ? 9 : 11}>
                <form onSubmit={handleEmailSubmit} style={{ padding: isMatch ? null : '0 5em 0 1em' }}>
                  <input type="hidden" name='heading' value="Contact Us page" />
                  <Grid container elevation={1} rounded spacing={1} display="contents">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="email"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="number"
                        type="number"
                        autoComplete="number"
                        className="text_contact"
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="outlined-multiline-static"
                        label="Message"
                        name="message"
                        multiline
                        rows={4}
                        autoComplete="message"
                        className="text_contact"
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack direction="row" spacing={3}>
                        <Stack direction="row" spacing={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="selling"
                                value="selling"
                                name="selling"
                                checked={isSellingChecked}
                                onChange={handleSellingChange}
                                sx={{ '&:hover': { bgcolor: 'transparent' } }}
                                disableRipple
                                color="default"
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                inputProps={{ 'aria-label': 'Checkbox demo' }} />
                            }
                            label="SELLING"
                          />
                          {/* <Typography sx={{ fontSize: '1rem', fontWeight: 400, pt: '8px' }}>SELLING</Typography> */}
                        </Stack>
                        <Stack direction="row" spacing={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="buying"
                                value="buying"
                                name="buying"
                                checked={isBuyingChecked}
                                onChange={handleBuyingChange}
                                sx={{ '&:hover': { bgcolor: 'transparent' } }}
                                disableRipple
                                color="default"
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                inputProps={{ 'aria-label': 'Checkbox demo' }} />
                            }
                            label="BUYING"
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={isMatch ? 12 : 3} >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ m: '1em 0em', backgroundColor: '#3f3326' }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Thankyou for writing to us! We have received your message and we will contact you soon.
                    </Alert>
                  </Snackbar>
                  <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      Please try again!
                    </Alert>
                  </Snackbar>
                </form>
              </Grid>
              <Grid item xs={isMatch ? 3 : 1} style={{ display: 'flex', justifyContent: 'end', }}>
                <StyledPaper isMatch={isMatch}>
                  <img width="50px" height="50px" src={kelly} alt="HomesWaState" style={{ margin: '1.2em 0em' }} />
                </StyledPaper>
              </Grid>
            </Grid>

          </Paper>
        </Container>
        <Divider />
        <Container maxWidth="md" sx={{ mt: '1em' }}>
          <Paper elevation={0} sx={{ backgroundColor: "#fff", }}>
            <Grid container spacing={0}>
              <Grid item xs={isMatch ? 12 : 6}>
                <Card elevation={0} sx={{ backgroundColor: "#f5f5f5", m: isMatch ? '1em' : '3em' }}>
                  <CardMedia
                    component="img"
                    src={Image}
                    alt="single image"
                    sx={{
                      padding: 2,
                      borderRadius: "12px", // Optional, adds inner rounding to the image itself
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={isMatch ? 12 : 6} display="flex" flexDirection="column" alignItems={isMatch ? "center" : "flex-start"} justifyContent="flex-start">
                <Card elevation={0} sx={{ backgroundColor: "#fff", mt: isMatch ? '1em' : '3em' }}>
                  <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                    <Typography sx={{
                      fontSize: isMatch ? '1.5em' : '1.5em',
                      fontWeight: 500,
                      textDecoration: 'underline',
                      textDecorationColor: '#02c9be',
                      textDecorationThickness: '2px',
                      textUnderlineOffset: '7px',
                    }}>Reach me Directly</Typography>
                    {/* <Typography sx={{ fontSize: isMatch ? '15px' : '1.2em', fontWeight: 400, textTransform: 'uppercase', color: '#827e7e' }}>Real Estate Agent</Typography> */}
                    <Typography sx={{ pt: '0.8em', fontSize: isMatch ? '15px' : '1.1em', fontWeight: 400, textTransform: 'uppercase', color: '#827e7e' }}>(425) 588-8470</Typography>
                    <Typography sx={{ fontSize: isMatch ? '15px' : '1.1em', fontWeight: 400, textTransform: 'lowercase', color: '#827e7e' }}>dguptahomes@gmail.com</Typography>
                    {/* <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase', color: '#827e7e' }}>Office : 425-877-8290</Typography> */}
                    {/* <Rating name="read-only" value="5" readOnly /> */}
                    {/* <Typography sx={{ fontSize: isMatch ? '15px' : '1.1em', fontWeight: 400, }}>5.0 out of 5 stars</Typography>
                    <Typography sx={{ fontSize: isMatch ? '15px' : '1.1em', fontWeight: 400, }}>
                      <Link href="https://www.zillow.com/profile/Deepti-Gupta/" underline='none' target="_blank" >
                        129 Zillow Reviews
                      </Link>
                    </Typography> */}
                  </Stack>
                  {/* <Divider>
                    <Typography sx={{ fontSize: isMatch ? '1.5em' : '2em', fontWeight: 500, }}> Connect with me</Typography>
                  </Divider> */}
                  <Stack direction="column" justifyContent={isMatch ? "center" : "flex-start"} alignItems={isMatch ? "center" : "flex-start"} spacing={1} sx={{ mt: '1em', }}>
                    <Typography sx={{
                      mt: '1em',
                      fontSize: isMatch ? '1.5em' : '1.5em',
                      fontWeight: 500,
                      textDecoration: 'underline',
                      textDecorationColor: '#02c9be',
                      textDecorationThickness: '2px',
                      textUnderlineOffset: '7px',
                    }}> Connect with me</Typography>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={isMatch ? 5 : 3} sx={{ pt: isMatch ? '1.5em' : '1em', }}>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                        <FaFacebook size={32} color="#1877F2" />
                      </Link>
                      <Link style={{ textDecoration: 'none', color: '#fff' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                        <FaWhatsapp size={32} color="#25D366" />
                      </Link>
                      <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                        <ButtonBase disableRipple>
                          <img src={insta} alt="Zillow" className={classes.imagelogo} />
                        </ButtonBase>
                      </Link>
                    </Stack>
                  </Stack>
                  <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                    <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={isMatch ? 2 : 3} sx={{ pt: isMatch ? '2em' : '3em', pb: '1em' }}>
                      <StyledButton1 variant='contained' startIcon={<PhoneIphone />} isMatch={isMatch}>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} href="tel:425-588-8470">Call Me</Link>
                      </StyledButton1>
                      <StyledButton1
                        variant='contained'
                        color="primary"
                        startIcon={<Mail />} isMatch={isMatch}>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} target="_blank"
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">Email</Link>
                      </StyledButton1>
                    </Stack>
                    <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={isMatch ? 2 : 3} sx={{ pt: isMatch ? null : '1em', pb: isMatch ? '2em' : '1em' }}>
                      <StyledButton1
                        variant='contained'
                        color="primary"
                        startIcon={<WhatsAppIcon fontSize='large' />} isMatch={isMatch}>
                        <Link
                          style={{ textDecoration: 'none', color: '#fff' }}
                          href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)"
                        >
                          WhatsApp me
                        </Link>
                      </StyledButton1>
                      <StyledButton1
                        variant='contained'
                        color="primary"
                        onClick={handleClick1}
                        startIcon={<Forum />} isMatch={isMatch}>
                        Send Message
                      </StyledButton1>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Divider />
        <Container maxWidth="md" sx={{ mt: isMatch ? '1em' : '2em', mb: isMatch ? '3em' : '2em' }}>
          <Grid container spacing={isMatch ? 0 : 1} sx={{ mb: isMatch ? '4em' : null }}>
            {/* <Grid item xs={isMatch ? 12 : 6}>
              {/* <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                <Typography sx={{
                  fontSize: isMatch ? '1.5em' : '1.5em',
                  fontWeight: 500,
                  textDecoration: 'underline',
                  textDecorationColor: '#02c9be',    // underline color
                  textDecorationThickness: '2px', // thickness of the underline
                  textUnderlineOffset: '7px',     // space between text and underline
                }}>
                  Reach me Directly
                </Typography>
                <Link color="primary" style={{ textDecoration: 'none', marginTop: '2em' }} href="tel:425-588-8470" >
                  <ButtonBase disableRipple>
                    <PhoneIphone fontSize="large" sx={{ color: '#9e9e9e' }} />
                    <Typography sx={{ color: '#9e9e9e', pl: '7px', fontSize: '1.3em', fontWeight: 400 }}>
                      425-588-8470
                    </Typography>
                  </ButtonBase>
                </Link>
                <Link color="primary" style={{ textDecoration: 'none', marginTop: '1em' }} target="_blank"
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                  <ButtonBase disableRipple>
                    <Email fontSize="large" sx={{ color: '#9e9e9e' }} />
                    <Typography sx={{ color: '#9e9e9e', pl: '7px', fontSize: '1.3em', fontWeight: 400 }}>
                      dguptahomes@gmail.com
                    </Typography>
                  </ButtonBase>
                </Link>
              </Stack> 
            </Grid> */}
            <Grid item xs={isMatch ? 12 : 10} display="flex" justifyContent="end" alignItems="start">
              <Stack direction={isMatch ? "column" : "row"} justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                <Stack sx={{ width: isMatch ? null : '300px', height: isMatch ? null : '200px' }}>
                  <img src={logoS2} style={{ paddingBottom: isMatch ? null : '3em', marginLeft: isMatch ? '1em' : '-4em' }} width={isMatch ? "90%" : "100%"} height={isMatch ? null : "100%"} alt="HomesWaState" />
                </Stack>
                <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                  <Typography sx={{
                    fontSize: isMatch ? '1.5em' : '1.5em',
                    fontWeight: 500,
                    textDecoration: 'underline',
                    textDecorationColor: '#02c9be',    // underline color
                    textDecorationThickness: '2px', // thickness of the underline
                    textUnderlineOffset: '7px',     // space between text and underline
                  }}
                  >
                    Stay Connected
                  </Typography>
                  <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={isMatch ? 5 : 3} sx={{ pt: '2em' }}>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                      <FaFacebook size={isMatch ? 32 : 40} color="#1877F2" />
                    </Link>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                      <FaWhatsapp size={isMatch ? 32 : 40} color="#25D366" />
                    </Link>
                    <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                      <ButtonBase disableRipple>
                        <img src={insta} alt="Zillow" width={isMatch ? '32px' : '40px'} height={isMatch ? '32px' : '40px'} />
                      </ButtonBase>
                    </Link>
                    <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.zillow.com/profile/Deepti-Gupta/">
                      <ButtonBase disableRipple>
                        <img src={Zillow} alt="Zillow" width={isMatch ? '32px' : '40px'} height={isMatch ? '32px' : '40px'} />
                      </ButtonBase>
                    </Link>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            {/* <Grid item xs={isMatch ? 12 : 6} display="flex" justifyContent="center" >
              <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                <Typography sx={{
                  fontSize: isMatch ? '1.5em' : '1.5em',
                  fontWeight: 500,
                  textDecoration: 'underline',
                  textDecorationColor: '#02c9be',    // underline color
                  textDecorationThickness: '2px', // thickness of the underline
                  textUnderlineOffset: '7px',     // space between text and underline
                }}
                >
                  Stay Connected
                </Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={isMatch ? 5 : 3} sx={{ pt: '2em' }}>
                  <Link style={{ textDecoration: 'none', color: '#fff' }} href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                    <FaFacebook size={isMatch ? 32 : 40} color="#1877F2" />
                  </Link>
                  <Link style={{ textDecoration: 'none', color: '#fff' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                    <FaWhatsapp size={isMatch ? 32 : 40} color="#25D366" />
                  </Link>
                  <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                    <ButtonBase disableRipple>
                      <img src={insta} alt="Zillow" width={isMatch ? '32px' : '40px'} height={isMatch ? '32px' : '40px'} />
                    </ButtonBase>
                  </Link>
                  <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.zillow.com/profile/Deepti-Gupta/">
                    <ButtonBase disableRipple>
                      <img src={Zillow} alt="Zillow" width={isMatch ? '32px' : '40px'} height={isMatch ? '32px' : '40px'} />
                    </ButtonBase>
                  </Link>
                </Stack>
              </Stack>
            </Grid> */}
          </Grid>
        </Container>
        {/* {
          isMatch ?
            <Container maxWidth="md">
              <Grid container spacing={isMatch ? 0 : 3} style={{ alignItems: "center", paddingBottom: "30px" }} >
                <Grid item xs={12} sm={12} md={6}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card className="container" elevation={5} rounded >
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <Typography sx={{ fontSize: '1.5em', fontWeight: 500 }}>Book a consultation call with Deepti today!!</Typography>

                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                          <StyledPaper isMatch={isMatch}>
                            <img width="50px" height="50px" src={kelly} alt="HomesWaState" style={{ margin: '1.2em 0em' }} />
                          </StyledPaper>
                        </Grid>
                      </Grid>
                      <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1} sx={{ mt: isMatch ? '1em' : '0em', bgcolor: '#e0e0e0', p: '1em' }}>
                        <Avatar
                          sx={{
                            width: 150,
                            height: 170,
                            borderRadius: "50%",   // Keeps the circular shape
                            bgcolor: "background.paper"
                          }}
                        >
                          <img src={Image} alt="single image"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: "contain",  // Ensures the whole image fits inside the Avatar
                              objectPosition: "center",  // Center the image within the Avatar
                            }}
                          />
                        </Avatar>
                        <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                          <Typography sx={{ fontSize: isMatch ? '1.5em' : '1.6em', fontWeight: 500, }}>Deepti Gupta</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Real Estate Agent</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Direct : 425-588-8470</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Office : 425-877-8290</Typography>
                          <Rating name="read-only" value="5" readOnly />
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 500, }}>5.0 out of 5 stars</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, }}>
                            <Link href="https://www.zillow.com/profile/Deepti-Gupta/" underline='none' target="_blank" >
                              114 Zillow Reviews
                            </Link>
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={isMatch ? 5 : 3} sx={{ p: isMatch ? '2em' : '3em 5em 2em 4em' }}>
                          <StyledButton variant='contained' startIcon={<PhoneIphone />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} href="tel:425-588-8470">Call Me</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<Mail />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} target="_blank"
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">Email</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<WhatsAppIcon fontSize='large' />} isMatch={isMatch}>
                            <Link
                              style={{ textDecoration: 'none', color: '#fff' }}
                              href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)"
                            >
                              WhatsApp me
                            </Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            onClick={handleClick1}
                            startIcon={<Forum />} isMatch={isMatch}>
                            Send Message
                          </StyledButton>

                        </Stack>
                        <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title" scroll="paper" sx={{ '&.MuiDialog-paper': { overflow: 'hidden' } }}>
                          <DialogTitle>
                            Book a consultation call with Deepti today!!
                            <IconButton
                              aria-label="close"
                              onClick={handleClose1}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <Close />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <form
                              onSubmit={handleEmailSubmit}>
                              <input type="hidden" name='heading' value="Contact Us page" />
                              <Grid container spacing={2} style={{ padding: '2em' }}>
                                <Grid item xs={12} >
                                  <TextField
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className="text_contact"
                                    // value={values.email}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    className="text_contact"
                                    // value={values.name}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    label="Number"
                                    name="number"
                                    type="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    // value={values.number}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"

                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    autoComplete="message"
                                    className="text_contact"
                                    // value={values.message}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={loading}
                                  // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                  >
                                    Send
                                  </Button>
                                </Grid>
                              </Grid>
                              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                  Thankyou for writing to us! We have received your message and we will contact you soon.
                                </Alert>
                              </Snackbar>
                              <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                  Please try again!
                                </Alert>
                              </Snackbar>
                            </form >
                          </DialogContent>
                        </Dialog>
                      </Stack>
                    </Card>
                  </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.center} style={{ marginBottom: isMatch ? '0em' : '14em' }}>
                  <Container component="main" maxWidth="xs" className={classes.gridmobpad}>
                    <Grid item xs={12} >
                      <Typography sx={{ fontSize: "20px", color: "#2979ff", }}>
                        Connect us on social media
                      </Typography>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">
                          <ButtonBase disableRipple>
                            <PhoneIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                          <ButtonBase disableRipple>
                            <FacebookIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                          <ButtonBase disableRipple>
                            <WhatsAppIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} alignItems="center">
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                          <ButtonBase disableRipple>
                            <Instagram color="primary" sx={{
                              width: "1.8em",
                              height: "2em",
                            }} />
                            <Typography className={classes.text} >
                              deeptigupta_realestate
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.zillow.com/profile/Deepti-Gupta/">
                          <ButtonBase disableRipple>
                            <img src={Zillow} alt="Zillow" className={classes.imagelogo} />
                            <Typography className={classes.text}>
                              Deepti-Gupta
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank"
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                          <ButtonBase disableRipple>
                            <EmailIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </Container>
            :
            <Container maxWidth="md">
              <Grid container spacing={isMatch ? 0 : 3} style={{ alignItems: "center", paddingBottom: "30px" }} >
                <Grid item xs={12} sm={12} md={6} className={classes.center} style={{ marginBottom: isMatch ? '0em' : '14em' }}>
                  <Container component="main" maxWidth="xs" className={classes.gridmobpad}>
                    <Grid item xs={12} >
                      <Typography sx={{ fontSize: "20px", color: "#2979ff", }}>
                        Connect us on social media
                      </Typography>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">
                          <ButtonBase disableRipple>
                            <PhoneIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                          <ButtonBase disableRipple>
                            <FacebookIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                          <ButtonBase disableRipple>
                            <WhatsAppIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                          <ButtonBase disableRipple>
                            <Instagram color="primary" sx={{
                              width: "1.8em",
                              height: "2em",
                            }} />
                            <Typography className={classes.text}>
                              deeptigupta_realestate
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.zillow.com/profile/Deepti-Gupta/">
                          <ButtonBase disableRipple>
                            <img src={Zillow} alt="Zillow" className={classes.imagelogo} />
                            <Typography className={classes.text}>
                              Deepti-Gupta
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank"
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                          <ButtonBase disableRipple>
                            <EmailIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>



                <Grid item xs={12} sm={12} md={6}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card className="container" elevation={5} rounded >
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <Typography sx={{ fontSize: '1.5em', fontWeight: 500 }}>Book a consultation call with Deepti today!!</Typography>

                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                          <StyledPaper isMatch={isMatch}>
                            <img width="50px" height="50px" src={kelly} alt="HomesWaState" style={{ margin: '1.2em 0em' }} />
                          </StyledPaper>
                        </Grid>
                      </Grid>
                      <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1} sx={{ mt: isMatch ? '1em' : '0em', bgcolor: '#e0e0e0', p: '1em' }}>
                        <Avatar
                          sx={{
                            width: 150,
                            height: 170,
                            borderRadius: "50%",   // Keeps the circular shape
                            bgcolor: "background.paper"
                          }}
                        >
                          <img src={Image} alt="single image"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: "contain",  // Ensures the whole image fits inside the Avatar
                              objectPosition: "center",  // Center the image within the Avatar
                            }}
                          />
                        </Avatar>
                        <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                          <Typography sx={{ fontSize: isMatch ? '1.5em' : '1.6em', fontWeight: 500, }}>Deepti Gupta</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Real Estate Agent</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Direct : 425-588-8470</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Office : 425-877-8290</Typography>
                          <Rating name="read-only" value="5" readOnly />
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 500, }}>5.0 out of 5 stars</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, }}>
                            <Link href="https://www.zillow.com/profile/Deepti-Gupta/" underline='none' target="_blank" >
                              129 Zillow Reviews
                            </Link>
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={isMatch ? 5 : 3} sx={{ p: isMatch ? '2em' : '3em 5em 2em 4em' }}>
                          <StyledButton variant='contained' startIcon={<PhoneIphone />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} href="tel:425-588-8470">Call Me</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<Mail />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} target="_blank"
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">Email</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<WhatsAppIcon fontSize='large' />} isMatch={isMatch}>
                            <Link
                              style={{ textDecoration: 'none', color: '#fff' }}
                              href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)"
                            >
                              WhatsApp me
                            </Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            onClick={handleClick1}
                            startIcon={<Forum />} isMatch={isMatch}>
                            Send Message
                          </StyledButton>

                        </Stack>
                        <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title" scroll="paper" sx={{ '&.MuiDialog-paper': { overflow: 'hidden' } }}>
                          <DialogTitle>
                            Book a consultation call With Deepti today!!
                            <IconButton
                              aria-label="close"
                              onClick={handleClose1}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <Close />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <form
                              onSubmit={handleEmailSubmit}>
                              <input type="hidden" name='heading' value="Contact Us page" />
                              <Grid container spacing={2} style={{ padding: '2em' }}>
                                <Grid item xs={12} >
                                  <TextField
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className="text_contact"
                                    // value={values.email}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    className="text_contact"
                                    // value={values.name}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    label="Number"
                                    name="number"
                                    type="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    // value={values.number}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"

                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    autoComplete="message"
                                    className="text_contact"
                                    // value={values.message}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={loading}
                                  // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                  >
                                    Send
                                  </Button>
                                </Grid>
                              </Grid>
                              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                  Thankyou for writing to us! We have received your message and we will contact you soon.
                                </Alert>
                              </Snackbar>
                              <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                  Please try again!
                                </Alert>
                              </Snackbar>
                            </form >
                          </DialogContent>
                        </Dialog>
                      </Stack>
                    </Card >
                  </Container >
                </Grid >
              </Grid >
            </Container >
        } */}
        < Footer />
      </Box >






    </div >
  )
}
export default ContactUS;
















