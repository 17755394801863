import React, { useEffect, useState } from 'react';
//import logo from './logo.svg';
import './App.css';
// import Home from './pages/Home';
import Properties from './pages/Properties';
import Detailpage from './pages/Detailpage';
import Signup from './user/Signup';
import LoginRaw from './user/LoginRaw';
import ForgotPassword from './user/ForgotPassword';
import { Route, Routes, useNavigate, } from 'react-router-dom';
import Adminindex from './admin/AdminIndex.js';
import Navbar from './components/Navbar';
import Theme from './components/Theme'
import { ThemeProvider } from "@material-ui/core";
import ReactGA from 'react-ga';
import { AuthProvider } from './user/AuthContext';
import { withPropertyConsumer } from './context';
import ComingSoon from './components/NavBar/ComingSoon';
import firebase from './firebase/firebase';
import SoldSoFarMerge from './components/NavBar/SoldSoFarMerge';
import Mortgage from './components/Mortgage/Mortgage';
import ContactUs from './components/NavBar/ContactUs';
import ReviewsContainer from './components/NavBar/Reviews/ReviewsContainer';
import { useLocation } from "react-router-dom";
// import Login from './user/Login';
import { Helmet } from 'react-helmet';
import CityListings from './Listings/CityListings';
import Listingpage from './pages/Listingpage';
import Staging from './components/staging/Staging'
import JoinOurTeam from './components/staging/JoinOurTeam';
import Blog from './Blog/Blog';
import BlogDetail from './Blog/BlogDetail';
import { BuyerInputs } from './Client Inputs/BuyerInputs';
import { SellerInputs } from './Client Inputs/SellerInputs';
import About from './pages/About';
import { Fab, styled, useMediaQuery, useTheme, Box, } from '@mui/material';
import UpcomingPro from './pages/UpcomingPro';
import Home from './pages/Home'
import Projectalex61 from './ProjectAlex61/Projectalex61';
import Deals from './Deals/Deals';
import Dealsdp from './Deals/Dealsdp';
import MortContainer from './components/Mortgage/MortContainer';
import NewConstAdmin from './user/NewConstAdmin';
import FeaturedListings from './Listings/FeaturedListings';
import OurDetails from './pages/OurDetails';
import banner22 from './images/Deepti.png';
import RentalInputs from './Client Inputs/RentalInputs';
import Contactus2 from './contact/Contactus2';
import BIpannel from './admin/BIPannel/BIpannel';
import { ClientInfo } from './Client Inputs/ClientInfo';
import  BuyerGuide  from './Client Inputs/BuyerGuide';
import SellerGuide from './Client Inputs/SellerGuide';


ReactGA.initialize('UA-194158145-1');

// const CustomFab = styled(Fab)(({ theme }) => ({
//   margin: 0,
//   top: 'auto',
//   right: 30,
//   bottom: 20,
//   left: 'auto',
//   position: 'fixed',
//   height: 'auto',
//   width: ' auto',
//   background: '#241b14',
//   borderRadius: '10px',
//   '&:hover': {
//     backgroundColor: '#241b14',
//     cursor: 'auto'
//   },
//   [theme.breakpoints.down("md")]: {
//     margin: 0,
//     top: 'auto',
//     right: 10,
//     bottom: '7em',
//     left: 'auto',
//     position: 'fixed',
//     height: '60%',
//     width: '53%',
//     background: '#241b14',
//     borderRadius: '10px',
//   },
//   [theme.breakpoints.down("sm")]: {
//     margin: 0,
//     top: 'auto',
//     right: 10,
//     bottom: '7em',
//     left: 'auto',
//     position: 'fixed',
//     height: '60%',
//     width: '56%',
//     background: '#241b14',
//     borderRadius: '10px',
//   },
//   [theme.breakpoints.down("xs")]: {
//     margin: 0,
//     top: 'auto',
//     right: 10,
//     bottom: '7em',
//     left: 'auto',
//     position: 'fixed',
//     height: '60%',
//     width: '56%',
//     background: '#241b14',
//     borderRadius: '10px',
//   },

// }))
// const CustomBox = styled(Box)(({ theme }) => ({
//   position: 'fixed',
//   bottom: -6,
//   right: '2em',
//   width: '20%',
//   borderRadius: '2px'
// }))

// const CustomFab1 = styled(Fab)(({ theme }) => ({
//   margin: 0,
//   top: 'auto',
//   right: 30,
//   bottom: 40,
//   left: 'auto',
//   position: 'fixed',
//   height: 'auto',
//   width: ' auto',
//   background: 'rgb(246, 246, 247)',
//   borderRadius: '10px',
//   '&:hover': {
//     backgroundColor: 'rgb(246, 246, 247)',
//     cursor: 'auto'
//   },
// }))

function App({ context, props }) {
  const [count, setCount] = useState([])
  // const [open1, setOpen1] = React.useState(true);
  const location = useLocation();
  // const history = useNavigate();
  // const theme = useTheme();
  // const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(true);
  const [domainName, setDomainName] = React.useState('');
  // const match = window.location.pathname.split('/')[1] === 'UpcomingProjects' && 'sevenwonders' ? true : false;
  // const match1 = window.location.pathname.split('/')[1] === 'sevenwonders' ? true : false;

  const domain = window.location.hostname;


  const Wrapper = ({ children }) => {
    const location = useLocation();
    React.useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  // const handleClose = () => {
  //   setOpen(false);
  // };


  // const handleClose1 = () => {
  //   setOpen1(false);
  // };


  // const handleClickOpen = () => {
  //   setOpen1(true);
  //   // setOpen1(true);
  // };

  // function handleScroll() {
  //   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  //   if (scrollPosition > 100) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (count.includes(window.location.pathname + window.location.search) === false)
      setCount(prevState => ([...prevState, window.location.pathname + window.location.search]))
    // setCount(window.location.pathname + window.location.search)}
  }, [location]);

  useEffect(() => {
    // if(count.length > 2){
    var user = firebase.auth().currentUser;

    if (user == null) {
      // setOpen10(true);

      // setTimeout(()=>  setOpen10(true),2000)
    }
  }, []);
  // }


  React.useEffect(() => {
    // Function to get the domain name from window.location
    const getDomainName = () => {
      const url = new URL(window.location.href);
      setDomainName(url.hostname);
    };

    // Call the function when the component mounts
    getDomainName();

    // Add event listener to handle changes in the URL
    const handleNavigationChange = () => {
      getDomainName();
    };

    window.addEventListener('popstate', handleNavigationChange);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
    };
  }, []);

  const getHref = () => {
    switch (domainName) {
      case 'www.dguptahomes.com':
        return "https://www.dguptahomes.com/";
      case 'www.homewastate.com':
        return "https://www.homewastate.com/";
      default:
        return "https://www.homewastate.com/";
    }
  };

  const renderText = () => {
    switch (domainName) {
      case 'www.dguptahomes.com':
        return 'DguptaHomes';
      case 'www.homewastate.com':
        return 'HomeWAState';
      default:
        return 'HomeWAState';
    }
  };

  var link = <a href="www.homewastate.com">www.homewastate.com</a>;
  return (
    <Wrapper>

      <ThemeProvider theme={Theme}>

        <Navbar />
        <Helmet>
          {/* <meta charSet="utf-8" /> */}
          <title>Find New / Resale homes in Redmond, Bellevue, Kirkland, Bothell, Lynnwood, Sammamish Homes for sale in. - {renderText()}</title>
          {/* <meta name="keywords" content="New homes in Bothell, homes in Bothell, Best homes in Bothell, Homes for sale in Bothell, Buy Homes in Bothell" />
          <meta property="og:image" content={`${banner22}`} />
          <meta name="description" content={`Finding new homes in Bothell is way too easier than ever. All you have to do is, visit ${<link rel="HomeWAState" href="https://www.homewastate.com/" />} and start exploring the best new homes in Bothell. Select your home at an affordable price easily, and scrolling through the website would help.`} />
          <link rel="HomeWAState" href="https://www.homewastate.com/" /> */}
          <meta property="og:title" content="New homes in Bothell, homes in Bothell, Best homes in Bothell, Homes for sale in Bothell, Buy Homes in Bothell" />
          <meta property="og:description" content={`Finding new homes in Bothell is way too easier than ever. All you have to do is, visit ${<link rel={renderText()} href={getHref()} />} and start exploring the best new homes in Bothell. Select your home at an affordable price easily, and scrolling through the website would help.`} />
          <meta property="og:image" content={`${banner22}`} />
          <meta property="og:url" content={getHref()} />
          <meta property="og:type" content="website" />
        </Helmet>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} className="Properties" />
            <Route path="/New-constructions" element={<NewConstAdmin />} className="Properties" />
            <Route path="/Project-Alexander61" element={<Projectalex61 />} className="Properties" />
            <Route path="/sevenwonders/" element={<Adminindex />} />
            <Route path="/Signup/" element={<Signup />} />
            <Route path="/Login/" element={<LoginRaw />} />
            <Route path="/user/forgot-password/" element={<ForgotPassword />} />
            <Route path="/comig-soon/" element={<ComingSoon />} />
            <Route path="/Blog/" element={<Blog />} />
            <Route path="/Blog/:address" element={<BlogDetail />} />
            {/* <Route path="/Availability/" element={<AvailiabilityAdminIndex />} /> */}
            <Route path="/Last-Solds/" element={<SoldSoFarMerge />} />
            <Route path="/Meet-Deepti" element={<About />} />
            <Route path="/FeaturedListings/" element={<BIpannel />} />
            {/* <Route path="/FeaturedListings/:Address" element={<FeaturedDetailPage />} /> */}
            <Route path="/Our-Listings/" element={<CityListings />} />
            <Route path="/Our-Listings/:MlsId" element={<Listingpage />} />
            <Route path="/Deals/" element={<Deals />} />
            <Route path="/Deals/:dealSlug" element={<Dealsdp />} />
            <Route path="/Staged-to-Sell/" element={<Staging />} />
            {/* <Route path="/Buyer-Information/" element={<BuyerInputs />} />
            <Route path="/Seller-Information/" element={<SellerInputs />} /> */}
            <Route path="/Rental-Information/" element={<RentalInputs />} />
            <Route path="/Client-Information/" element={<ClientInfo />} />
            <Route path="/Buyer-Guide" element={<BuyerGuide />} />
            <Route path="/Seller-Guide" element={<SellerGuide />} />
            <Route path="/Mortgage" element={<Mortgage />} />
            <Route path="/Contact-US/" element={<ContactUs />} />
            <Route path="/Our-Details/" element={<OurDetails />} />
            <Route path="/Reviews/" element={<ReviewsContainer />} />
            <Route path="/Self-Service" element={<MortContainer />} />
            <Route path="/Contact-US-CRYDandiya/" element={<Contactus2 />} />
            <Route path="/UpcomingProjects" element={<UpcomingPro />} />
            <Route path="/JoinOurTeam" element={<JoinOurTeam />} />
            {/* <Route path="/ContactDeepti" element={<ContactDeepti />} /> */}
            <Route path="/New-constructions/:slug" element={<Detailpage />} />

          </Routes>
          {/* {loading ? null :
            <>
              {
                (
                  match === true ? null
                    :
                    <>
                      <>
                        <Chatbot />
                      </>
                      {isMatch ? null :
                        <>
                    
                          {open && <CustomFab size="large">
                            <Stack sx={{ display: 'flex', flexDirection: 'column', }}>
                              <Stack alignItems="end" sx={{ mt: '5px', }}><Cancel onClick={handleClose}
                                sx={{
                                  ml: '2em',
                                  color: '#f50057',
                                  '&:hover':
                                  {
                                    cursor: 'pointer'
                                  }
                                }} />
                              </Stack>
                              <img style={{
                                width: '300px',
                                padding: '15px',
                              }} src={Image} alt="Upcoming" />
                              <Stack alignItems="center" sx={{ pb: '15px' }}>
                                <Button
                                  variant='filled'
                                  sx={{
                                    color: 'black',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    borderRadius: '40px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: 'white',
                                    }
                                  }}
                                  onClick={() => history('/UpcomingProjects')}
                                >
                                  Book now
                                </Button>
                              </Stack>
                            </Stack>
                          </CustomFab>
                          }
                        </>

                      }
                    </>
                )
              }
            </>
          } */}
        </AuthProvider>
      </ThemeProvider>

    </Wrapper>
  );
}

export default withPropertyConsumer(App);

// {
//   "rules": {
//     ".read": "true",  // 2020-10-25"
//       ".write": "'KxOcHVDLPMfqSEy3tR8eh7oCLJu2'== auth.uid"
//       // ".write": "auth != null"
//     // ".write": "true",  // 2020-10-25
//   }
// }