

import React, { useEffect } from 'react';
// import { useContext } from 'react';
// import { PropertyContext } from '../context'
import defaultmage from '../images/room-1.jpeg';
import '../components/AvilableHome.css';
import { withPropertyConsumer } from '../context';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import '../components/Availability.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import '../components/NavBar/NavBar.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import banner22 from '../images/Bannermob13.png';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import AppBar from '@material-ui/core/AppBar';
import CardActionArea from '@material-ui/core/CardActionArea';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import ButtonBase from '@material-ui/core/ButtonBase';
import RoomIcon from '@material-ui/icons/Room';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SortIcon from '@material-ui/icons/Sort';
import ReplayIcon from '@material-ui/icons/Replay';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import firebase from '../firebase/firebase';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Login from '../user/Login';

const getUnique = (items, value) => {
  return [...new Set(items.map(item => item[value]))]
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperD: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  papermob: {
    padding: 10,
    margin: 'auto',
  },
  image: {
    width: 128,
    height: 80
  },
  imagemob: {
    width: 80,
    height: 80
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  imgmob: {
    margin: 'auto',
    display: 'block',
    maxHeight: '80px',
    borderRadius: "5px",
    overflow: "hidden",
    height: "100%",
    cursor: 'default',
  },
  gridCenter: {
    alignItems: "center"
  },
  GridSpace: {
    padding: "0px 30px"
  },
  cardGrid: {
    marginTop: "8px",
  },
  bannerPaper: {
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    padding: 34,
    paddingTop: 60
  },
  textmob1: {
    color: "#3d4152",
    marginTop: "1px",
    fontSize: "1rem",
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 0
  },
  textmob2: {
    width: "100%",
    color: "#7e808c",
    fontSize: ".83rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
  textmob3: {
    color: "#f69022",
    fontSize: ".85rem"
  },
  textAC: {
    color: "#138e22",
    fontSize: ".85rem"
  },
  textPen: {
    color: "#FF5722",
    fontSize: ".85rem"
  },
  textClos: {
    color: "#ff0000",
    fontSize: ".85rem"
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: 900,
    borderRadius: '800px'

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontWeight: 420,
    fontSize: '15px',
    width: 100,

  },

  searchroot: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 400,

    [theme.breakpoints.down("xs")]: {
      width: "auto",
      borderRadius: '4px'
    },
    // margin: 'auto',
    borderRadius: "30px 0px 0px 30px",
  },
  search_lable: {
    fontWeight: 900,
    fontSize: '15px',
    [theme.breakpoints.down("md")]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '80px',
      textOverflow: "ellipsis"
    },
  },
  search_input: {
    fontWeight: 500,

  },
  divpadding: {
    paddingBottom: 10,
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down("md")]: {
      minWidth: 140
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 100
    },
  },

  innerpaper: {
    width: 'fit-content',
    margin: "auto",
    padding: "0px 10px",
  },
  filterPaper: {
    borderRadius: "10px"
  },
  root2: {
    display: 'flex',
    background: "#d91c25",
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer"
  },
  root3: {
    display: 'flex',

    background: 'linear-gradient(45deg, #4fbadafc, #3a85d2cf)',
    borderRadius: 0,
    padding: 10,
    color: "white",
    alignItems: "center",
    cursor: "pointer"
  },
  details: {
    paddingBottom: 8
  },
  texttwo: {
    fontSize: "1.2rem"
  }
}));


function Citys(props) {

  const [newData, SetNewData] = React.useState()
  const classes = useStyles();
  const address = props.address;
  var str = address.slice(0, -10);

  const refreshPage = () => {
    window.location.reload(false);
  }

  // console.log("address", str)



  
  return (
    <>
      <Grid item key={props} xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <Link underline='none' to={`/Our-Listings/${props.MlsId}`} onClick={refreshPage} style={{ textDecoration: 'none' }} >
            <Paper className={classes.papermob} elevation={0} style={{ background: "#fafafa" }}>
              <Grid container spacing={1} style={{ alignItems: "center" }}>
                <Grid item>
                  <ButtonBase className={classes.imagemob}>
                    <img className={classes.imgmob} alt="complex" src={props.featuredUrl || props.url || defaultmage} />
                    {props.Tag == 'Recommended' && <div className="top-Right_Rec_mob_avb">Recommended</div>}
                  </ButtonBase>
                </Grid>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs style={{cursor: 'text'}}>
                    <Typography gutterBottom variant="subtitle1" className={classes.textmob1}>
                      {str}
                    </Typography>
                    <Grid item >
                      <Grid item xs container direction="row" style={{ alignItems: "center" }}>
                        <Grid item >
                          <Typography className={classes.textmob3}>
                            <RoomIcon color="secondarytext" style={{ width: ".85em", height: ".85em" }} /> {props.city}
                          </Typography>
                        </Grid>
                        <Grid item xs container style={{ justifyContent: "center" }}>
                          <Grid item >
                            <Typography className={classes.textmob3}>
                              <AttachMoneyIcon color="secondarytext" style={{ width: ".85em", height: ".85em" }} />
                            </Typography>
                          </Grid >
                          <Grid item >
                            <Typography className={classes.textmob3}>
                              {(() => {
                                if (!props.startprice) {
                                  return (
                                    <>
                                      Not released
                                    </>)
                                }
                                else {
                                  return (
                                    <div>
                                      <NumberFormat value={props.startprice} displayType={'text'} thousandSeparator={true} />
                                    </div>)
                                }
                              })()}

                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid item >
                      <Typography variant="subtitle1">
                        <Link underline='none' to={`/Our-Listings/${props.MlsId}`} onClick={refreshPage} style={{ textDecoration: 'none', color: "rgb(49 11 255 / 42%)" }} >
                          <ChevronRightRoundedIcon />Know More
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </div>
      </Grid>
    </>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const CitysSub = ({ context, props }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);
  const {
    city, ...other } = props;


  const {
    listings,
    getData,
  } = context;

let soldListings =   listings;

soldListings = [
...soldListings.filter(house => house.city === props.city).filter(house => house.ActiveStatus === "Sold"),
] 


  useEffect(() => {
    if (listings.length === 0) {
      getData()
    }

  }, [])


  return (
    <div>


      <div>
        <Container className={classes.cardGrid} maxWidth="md">
          {soldListings.length > 0 &&
            <div className={classes.hold}  >
              <div className={classes.details}>
                <Card className={classes.root2} elevation={5}>
                  <Typography component="h5" variant="h5" className={classes.texttwo}>
                    Recently Sold by Deepti
                  </Typography>
                </Card>
              </div>
            </div>}
          <Grid container spacing={2}>
            {soldListings
              .sort((propsa, propsb) => (propsb.OfferDate > propsa.OfferDate) ? 1 : -1)
              .map((card) => (
                <Citys community={card.community} builder={card.builders}
                  startprice={card.startprice} Tag={card.Tag} slug={card.slug}
                  propertyname={card.propertyname} city={card.city} url={card.url} MlsId={card.MlsId} address={card.address} featuredUrl={card.featuredUrl} />
              ))}
          </Grid>
        </Container>
      </div>


    </div>

  )
}
export default withPropertyConsumer(CitysSub);
















