import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography, Box, Alert, Paper, styled, Grid, useMediaQuery, Select, InputAdornment, FormControl, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Snackbar, Backdrop, AppBar, Toolbar, IconButton, Tooltip } from "@mui/material";
import banner22 from '../images/Bannermob13.png';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Footer from '../components/Footer';
import { CheckCircleOutline } from "@mui/icons-material";
import Slide, { SlideProps } from '@mui/material/Slide';
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    phone: yup
        .string()
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
});

const CustomBanner = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 34,
    paddingTop: 60
}))

const CustomTextField = styled(TextField)({
    backgroundColor: '#fff',
    '& label.Mui-focused': {
        color: 'grey',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'grey',
        },
    },

})

const countries = [
    { code: "US", dialCode: "+1" },
    { code: "IN", dialCode: "+91" },
    { code: "UK", dialCode: "+44" },
];

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, mt: 2 }}>
                <Typography variant="body1" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const Guide = ({ pageTitle, pdfUrl }) => {
    const isMatch = useMediaQuery('(max-width:600px )');
    const navigate = useNavigate();// 🔹 Replace with actual PDF path
    const [step, setStep] = useState(1); // 🔹 Track form progress: 1 = Form, 2 = Thank You, 3 = PDF
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [pdfDownloaded, setPdfDownloaded] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        let timer;
        if (showProgress) {
            timer = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(timer);
                        setStep(3); // ✅ Open Dialog when progress reaches 100%
                        return 100;
                    }
                    return prevProgress + 10;
                });
            }, 800);
        }
        return () => clearInterval(timer);
    }, [showProgress]);


    useEffect(() => {
        if (step === 3) {
            downloadPDF();
            reset(); // 🔹 Automatically download PDF when dialog opens
        }
    }, [step === 3]);

    const sendEmailNotification = (data) => {
        const serviceId = 'service_b9v5hfs';
        const templateId = 'template_lc2aseu';
        const userId = 'user_c6L1p3GVRkDc5i4AlvRat';

        const emailParams = {
            title: pageTitle,
            user_name: data.name,
            user_email: data.email,
            user_phone: data.phone,
        };

        emailjs.send(serviceId, templateId, emailParams, userId)
            .then(() => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });
    };


    const onSubmit = (data) => {
        sendEmailNotification(data);
        setStep(2);
        setShowProgress(true);
        setProgress(0); // Reset progress when form is submitted
    };

    const downloadPDF = () => {
        setTimeout(() => {
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = `${pageTitle} Guide.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }, 3000); // Start download after 3 seconds
    };


    const handleClose = () => {
        navigate("/");
    };


    return (
        <Box sx={{ margin: 0, background: "white" }} >
            <CustomBanner>
                <Container sx={{ marginTop: "10px", }} maxWidth="md">
                    <div className="BAnner_text_Nav">
                        <h3>{pageTitle} Guide </h3>
                    </div>
                </Container>
            </CustomBanner>
            <Container maxWidth="sm" sx={{ mb: 5 }}>
                <Box sx={{ mt: 5, p: 3, boxShadow: 3, borderRadius: 2, bgcolor: "background.paper", textAlign: 'center' }}>
                    <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ fontSize: isMatch ? '1.5em' : '2em', fontWeight: 500, fontFamily: 'auto', }}>
                                Get Your Free {pageTitle} Guide
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: "#555", mb: 1, fontSize: '1em', fontWeight: 400 }}>
                                Fill in your details to access the guide instantly
                            </Typography>
                        </Grid>
                    </Grid>
                    {step === 1 && (<form onSubmit={handleSubmit(onSubmit)}>
                        <CustomTextField
                            fullWidth
                            label="Your Full Name"
                            margin="normal"
                            {...register("name")}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            required
                        />
                        <CustomTextField
                            fullWidth
                            label="Your Email Address"
                            margin="normal"
                            {...register("email")}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            required
                        />
                        {/* <CustomTextField
                      fullWidth
                      label="Your Phone Number"
                      required
                      {...register("phone")}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      sx={{ bgcolor: "white", mt: 2 }}
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <FormControl>
                                      <Select
                                          value={country}
                                          onChange={(e) => setCountry(e.target.value)}
                                          displayEmpty
                                          disableUnderline
                                          sx={{
                                              '& .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none', // Remove the default border
                                              },
                                              '& .MuiSelect-select': {
                                                  padding: 0, // Remove padding to align with the text field
                                              },
                                              '& .MuiSelect-icon': {
                                                  right: 0, // Adjust icon position
                                              },
                                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none', // Remove border on hover
                                              },
                                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                  border: 'none', // Remove border on focus
                                              },
                                              '&.Mui-focused .MuiInputBase-input': {
                                                  border: 'none', // Remove border when focused
                                              },
                                          }}
                                      >
                                          {countries.map((country) => (
                                              <MenuItem key={country.code} value={country.dialCode}>
                                                  {country.dialCode}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>
                              </InputAdornment>
                          ),
                      }}

                  /> */}
                        <CustomTextField
                            fullWidth
                            label="Your Phone Number"
                            margin="normal"
                            {...register("phone")}
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!isValid}
                                sx={{ width: '70%', mt: '1em', bgcolor: "#3f3326", ":hover": { bgcolor: "#5c4d39" } }}>
                                Get the Guide
                            </Button>
                        </Grid>
                    </form>)}
                    {step === 2 && showProgress && (
                        <Box sx={{ mt: 5, p: 3, boxShadow: 3, borderRadius: 2, bgcolor: "#dff0d8", textAlign: "center" }}>
                            <Typography variant={isMatch ? "h6" : "h5"} color="green">
                                ✅ Thank You for Submitting!
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Please wait while we prepare your guide...
                            </Typography>
                            <LinearProgressWithLabel sx={{ mt: 2 }} value={progress} />
                        </Box>
                    )}
                </Box>
                {/* <Snackbar
                    open={showAlert}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    TransitionComponent={SlideTransition}
                >
                    <Alert onClose={handleClose}
                        severity="info"
                        variant="filled"
                        sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Typography variant="body1">Thank you for submitting!</Typography>
                    </Alert>
                </Snackbar> */}
            </Container>
            <Dialog fullScreen
                open={step === 3}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="Close PDF Viewer" enterDelay={500} leaveDelay={200}>
                            <IconButton
                                edge="end"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    ":hover": {
                                        backgroundColor: '#fff',
                                        color: '#000',
                                    }
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                {!pdfLoaded && (
                    <Backdrop open={!pdfLoaded} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                {/* <DialogActions sx={{ position: "absolute", top: 0, right: 0, p: 1 }}>
                    <IconButton onClick={() => setShowPDF(false)} sx={{ color: "white" }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </DialogActions> */}
                <Box sx={{ width: "100vw", height: "100vh", bgcolor: "black", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                    {/* PDF Display */}
                    <iframe
                        src={pdfUrl}
                        title="PDF Viewer"
                        width="100%"
                        height="100%"
                        style={{
                            border: "none",
                            overflow: "hidden"
                        }
                        }
                        onLoad={() => setPdfLoaded(true)} />
                </Box>
            </Dialog>
            <div>
                <Footer />
            </div>
        </Box >
    )
}

export default Guide